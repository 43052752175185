import { IS_SANDBOX_FRONTEND, IS_STAGING_FRONTEND } from "@smartrr/shared/constants";
import { ISODateString } from "@smartrr/shared/entities/ISODateString";
import { WSRoutes } from "@smartrr/shared/typedVendorApi/TypedVendorRoutes";
import config from "@vendor-app/config";
import { DateTime } from "luxon";
import { v4 } from "uuid";

import { typedFrontendVendorApi } from "../utils/typedFrontendVendorApi";

const RETRY_TIMEOUT = 60 * 1000;

export interface Topic<Path extends keyof WSRoutes> {
  send(msg: WSRoutes[Path]["req"]["params"]): Promise<WSRoutes[Path]["res"]>;
}

export class SmartRRWebSocket {
  private wsready?: Promise<void>;
  private ws?: WebSocket;
  private topics = new Map<string, (msg: any) => void>();

  constructor(private ticket: string) {}

  static async create(orgId: string): Promise<SmartRRWebSocket> {
    const res = await typedFrontendVendorApi.postReq("/auth-tickets/issue");
    if (res.type !== "success") {
      throw new Error("Can't get an auth ticket for web sockets.");
    }

    const { ticket, expires } = res.body;
    const expiresDate = ISODateString.fromString(expires);
    const revokeTimeout = expiresDate.toMillis() - DateTime.now().toUTC().toMillis();

    const orgWS = new SmartRRWebSocket(ticket);
    await orgWS.init();

    setTimeout(async () => {
      orgWS?.ws?.close();
      await this.create(orgId);
    }, revokeTimeout);

    return orgWS;
  }

  private async init() {
    if (!this.ws) {
      this.ws =
        typeof window === "undefined"
          ? require("ws")
          : new WebSocket(
              IS_STAGING_FRONTEND
                ? `wss://app.staging.smartrr.com/${this.ticket}`
                : IS_SANDBOX_FRONTEND
                  ? `wss://app.sandbox.smartrr.com/${this.ticket}`
                  : config.environment === "production"
                    ? `wss://app.smartrr.com/${this.ticket}`
                    : `ws://localhost:3001/${this.ticket}`
            );
      this.wsready = new Promise(res => {
        const ws = this.ws;
        if (ws) {
          ws.addEventListener("open", () => {
            res();
          });
          ws.addEventListener("message", msg => {
            try {
              const { topic, data } = JSON.parse(msg.data);
              if (typeof topic === "string" && this.topics.has(topic)) {
                const fn = this.topics.get(topic);
                if (typeof fn === "function") {
                  fn.call(this, data);
                }
              }
            } catch (error) {
              console.error(error);
            }
          });
          ws.addEventListener("close", () => {
            setTimeout(async () => {
              await this.init();
            }, RETRY_TIMEOUT);
          });
        }
      });
    }
    return this.wsready!;
  }

  __createTopic<Path extends keyof WSRoutes>(route: Path, onMessage: (msg: WSRoutes[Path]["res"]) => void) {
    // Generate a new id to avoid promises conflicts.
    const topic = v4();
    this.topics.set(topic, onMessage);
    const that = this;
    return {
      topic,
      send(msg: WSRoutes[Path]["req"]["params"]) {
        that.ws?.send(
          JSON.stringify({
            topic,
            data: {
              route,
              params: msg,
            },
          })
        );
      },
    };
  }

  createTopicListener<Path extends keyof WSRoutes>(route: Path, onMessage: (msg: WSRoutes[Path]["res"]) => void) {
    const that = this;
    return that.__createTopic(route, onMessage);
  }

  createTopic<Path extends keyof WSRoutes>(route: Path): Topic<Path> {
    const that = this;
    return {
      send(msg) {
        return new Promise(res => {
          that.__createTopic(route, res).send(msg);
        });
      },
    };
  }
}
